/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/moment@2.24.0/min/moment.min.js
 * - /npm/moment@2.26.0/locale/ro.min.js
 * - /npm/jquery-countdown@2.2.0/dist/jquery.countdown.min.js
 * - /npm/popper.js@1.12.9/dist/umd/popper.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 * - /npm/clamp-js@0.7.0/clamp.min.js
 * - /npm/body-scroll-lock@3.0.3/lib/bodyScrollLock.min.js
 * - /npm/wowjs@1.1.3/dist/wow.min.js
 * - /npm/sweetalert2@10.3.7/dist/sweetalert2.min.js
 * - /npm/toastr@2.1.4/build/toastr.min.js
 * - /npm/lozad@1.16.0/dist/lozad.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
